// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-activities-index-js": () => import("/opt/render/project/src/src/templates/activities/index.js" /* webpackChunkName: "component---src-templates-activities-index-js" */),
  "component---src-templates-blogs-index-js": () => import("/opt/render/project/src/src/templates/blogs/index.js" /* webpackChunkName: "component---src-templates-blogs-index-js" */),
  "component---src-pages-index-js": () => import("/opt/render/project/src/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-mount-damavand-index-js": () => import("/opt/render/project/src/src/pages/mount-damavand/index.js" /* webpackChunkName: "component---src-pages-mount-damavand-index-js" */)
}

